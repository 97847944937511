<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-4banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">商情诊断管理分析</div>
            <div class="head-explainBox-content1">中国某知名调味品生产公司</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">客户公司在业务快速发展的同时，非常重视数据方面的投入。数据仓库已实施上线多年，虽然为业务扩张提
                供了有力的数据支持，但仍面临诸多挑战。通过商情诊断管理分析项目，并借助解决方案中针对产品、客户、区域的销售分析体系模板，业务部门结合
                企业自身情况建立了分析指标。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">实施方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">市场/销售数据难以支持精准决策</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据时效性无法满足业务的需求</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据质量需要进行改进</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据服务成本较高</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 31rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4销售执行.svg"/>
                        <div>
                            <div class="title1">销售执行分析模块  </div>
                            <div class="title2">轻松、方便地创建或修改主数据。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4销售结构.svg"/>
                        <div>
                            <div class="title1">销售结构分析模块</div>
                            <div class="title2">平台可针对各个产品、各个客户、各个区域销售情况进行深入分析</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-4营销活动.svg"/>
                        <div>
                            <div class="title1">营销活动ROI分析模块</div>
                            <div class="title2">平台提供了收入、成本、收益率分析功能，帮助客户及时掌握各营销活动盈利情况</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">平台帮助管理层与销售部门全面了解业务；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">营销与渠道分析应用达到毫秒级的数据分析响应能力；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">在指标框架指导下，对指标进行了标准化和细化描述，统一理解和计算逻辑；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">ETL整体执行效率较线下的数据仓库提升 <div class="title-6"> 6 </div>倍；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">相比使用本地IDC，使用Amazon降低总体拥有成本（TCO）50%。</div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'商情诊断管理分析,销售执行分析模块,销售结构分析模块,营销活动ROI分析模块' },
        { name:'description', content:'客户公司在业务快速发展的同时，非常重视数据方面的投入。数据仓库已实施上线多年，虽然为业务扩张提 供了有力的数据支持，但仍面临诸多挑战。通过商情诊断管理分析项目，并借助解决方案中针对产品、客户、区域的销售分析体系模板，业务部门结合 企业自身情况建立了分析指标。' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
        let top = t - 100; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
            if (currentTop <= top) {
                //   window.scrollTo(x,y) y为上下滚动位置
                window.scrollTo(0, currentTop - navHeight - 40);
                requestId = window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(requestId);
            }
            //向下滚动
            currentTop += speed;
            } else {
            if (top <= currentTop) {
                //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                window.scrollTo(0, currentTop - speed - navHeight);
                requestId = window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(requestId);
            }
            //向上滚动
            currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop - 100
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>400){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>800){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
            // 5.遍历锚点元素的offsetTop值
            // for (let i = 0; i < contentsOffsetTop.length; i++) {
            //     // 5.1 设置第一项导航默认为选中状态
            //     if (i === 0) {
            //     navChildren[0].classList.add('head-list-item-ischeck')
            //     } else if (scrollTop > contentsOffsetTop[i - 1]) {
            //     // 排他思想
            //     for (let j = 0; j < contentsOffsetTop.length; j++) {
            //         navChildren[j].classList.remove('head-list-item-ischeck')
            //         navChildren[i].classList.add('head-list-item-ischeck')
            //     }
            //     } else {
            //     navChildren[i].classList.remove('head-list-item-ischeck')
            //     }
            // }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    margin-top: 0.2rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #ED5847;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 26.7rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 14.6rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #ED5847;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2.5rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.2rem 0 0 1.2rem;
        }
    }
}
</style>
