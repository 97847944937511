var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/selected/03-2-4banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"31rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox"},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("商情诊断管理分析")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("中国某知名调味品生产公司")]),_c('div',{staticClass:"head-explainBox-line"}),_c('div',{staticClass:"head-explainBox-title2"},[_vm._v("项目介绍")]),_c('div',{staticClass:"head-explainBox-content2"},[_vm._v("客户公司在业务快速发展的同时，非常重视数据方面的投入。数据仓库已实施上线多年，虽然为业务扩张提 供了有力的数据支持，但仍面临诸多挑战。通过商情诊断管理分析项目，并借助解决方案中针对产品、客户、区域的销售分析体系模板，业务部门结合 企业自身情况建立了分析指标。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("市场/销售数据难以支持精准决策")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("数据时效性无法满足业务的需求")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("数据质量需要进行改进")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("数据服务成本较高")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-4销售执行.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("销售执行分析模块 ")]),_c('div',{staticClass:"title2"},[_vm._v("轻松、方便地创建或修改主数据。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-4销售结构.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("销售结构分析模块")]),_c('div',{staticClass:"title2"},[_vm._v("平台可针对各个产品、各个客户、各个区域销售情况进行深入分析")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-4营销活动.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("营销活动ROI分析模块")]),_c('div',{staticClass:"title2"},[_vm._v("平台提供了收入、成本、收益率分析功能，帮助客户及时掌握各营销活动盈利情况")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex","height":"5rem"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"special-style"},[_vm._v("平台帮助管理层与销售部门全面了解业务；")])]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex","height":"5rem"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"special-style"},[_vm._v("营销与渠道分析应用达到毫秒级的数据分析响应能力；")])]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex","height":"5rem"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"special-style"},[_vm._v("在指标框架指导下，对指标进行了标准化和细化描述，统一理解和计算逻辑；")])]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex","height":"5rem"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"special-style"},[_vm._v("ETL整体执行效率较线下的数据仓库提升 "),_c('div',{staticClass:"title-6"},[_vm._v(" 6 ")]),_vm._v("倍；")])]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex","height":"5rem"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_c('div',{staticClass:"special-style"},[_vm._v("相比使用本地IDC，使用Amazon降低总体拥有成本（TCO）50%。")])])])
}]

export { render, staticRenderFns }